/* Naming conventions here are currently WIP. */

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  font-family: inherit;
  line-height: inherit;
  color: inherit;
}

div, article, section, header, footer, nav, ul, li { 
  position: relative; 
}

p, div { 
  margin-bottom: 1em; 
}

div.App { 
  margin-bottom: 4em;

}

img, .img-frame {
  display: block;
  width: 100%; 
}

html {
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.2;
  color: #333;
}

body {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  overflow-x: scroll;
  overflow-y: hidden;
 }

h1, h2, h3, h4, h5, h6	{
  line-height: 2.0 ;
 
}

a {
  text-decoration: none;
}

/* MUI css */
.h-full {
  height: 100% !important;
}
.h-full-screen {
  height: 100vh;
}
.hidden {
  display: none;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block !important;
 }
    
.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between !important;
}

.justify-around {
  justify-content: space-around;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}
.items-stretch {
  align-items: stretch;
}
.flex-grow {
  flex-grow: 1;
}
.overflow-auto {
 overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden;
}
.scroll-y {
  overflow-x: hidden;
  overflow-y: scroll;
} 
.position-relative {
  position: relative;
}
.position-bottom {
  position: absolute;
  bottom: 0;
}
.text-center {
 text-align: center;
}
.align-middle {
  vertical-align: middle;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.x-center {
   left: 50%;
   transform: translateX(-50%);
}
.y-center {
  top: 50%;
  transform: translateY(-50%);
}
.w-full {
  width: 100%;
}
.w-full-screen {
  width: 100vw;
}

/* Custom */
.header-menu-item {
  margin-left: 2%;
  margin-right: 2%;
}
   
.content {
      list-style: none;
      color: inherit;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      display: flex;
      -webkit-box-flex: 1;
      flex-grow: 1;
      padding: 1.2em;
      background-color: #fff;
      border-radius: 8px 8px 0px 0px;
}

.body {
      font-size: 12px;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-direction: row;
      display: flex;
      line-height: 20px;
      padding-top: 2em;
      width: 80%;
}

.title {
      list-style: none;
      margin: 0;
      padding: 0;
      font-weight: 600;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-direction: row;
      display: flex;
      line-height: 24px;
      width: 80%;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.padding-top {
  padding-top: 2em;
}
.padding-bottom {
  padding-bottom: 2em;
}
.padding-left {
  padding-left: 2em;
}
.padding-right {
  padding-right: 2em;
}
.margin-top {
  padding: 2em;
  
}
.margin-bottom {
  margin-bottom: 2em;
}

.margin-bottom-additional {
  margin-bottom: 3em;
}
.margin-left {
  margin-left: 2em;
}

.margin-left-upvote {
  margin-left: 0.9em;
}
.margin-auto {
  margin: auto;
}

.post-form {
  position: absolute;
  top: 100;
}

.vote-container {
  display: flex;
  justify-content: space-between;
  margin-top: 3em;
}
  
.vote-ui-container {
  color: #000;
  fill: #000;
  font-size: 16px;
  font-weight: 600;
  -webkit-box-align: center;
  align-items: center;
  display: inline-flex;
  flex-wrap: nowrap;
  -webkit-box-pack: center;
  justify-content: center;

  }
  
  .width-80p {
    width: 80%;
  }

  .width-80vw {
    width: 80vw;
  }

  .h-custom {
    height: 35%;
  }

  .landing {
    width: 100vw;
  }

  .margin-custom {
    margin-top: -24px;
  }
  .margin-custom-bottom {
 
    height: 100%;
  }

  .margin-sides {
    margin-left: 1em;
    margin-right: 1em;

  }

  .border-radius-top {
    border-radius: 8px;
    border: solid .5px rgba(184, 184, 184, 0.4);
  }
  .border-radius-bottom {
    margin-top: -8px;
   
  }

  .box {
    border-top: solid .5px rgba(184, 184, 184, 0.4);
    margin: 0em;
    padding: 1em;
    margin-top: -1em;
   
  }

  .border-top {
    padding-top: 2em;
  }

  
  .padding-right-vote {
    padding-right: 0.0em;
    margin-right: 0.3em;
    margin-bottom: 0.3em;
  }

  .margin-top-upvote {
    margin-top: 0.3em;
  }
  .margin-downvote {
    margin-top: 0.00em;
  }
  .margin-votes {
    margin-top: 1em;
    margin-right: .1em;

  }
  .background-comment{
    border-radius: 4px;
    padding: 1.2em;
    width: 100%;
    flex-wrap: wrap;
    background-color: #fff;
  }

  .margin-top-post-form {
    margin-top: -.6em;
  }

  .background-img {
    background-image: url("https://images.unsplash.com/photo-1557683316-973673baf926?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1615&q=80");
  }

  .background-white {
    background-color: white;
  }

/* Media queries */

/**** Small devices ****/
@media screen and ( min-width: 47em ) {
  .breakpoint {
    overflow-x: scroll;
    flex-grow: 1;
    height: 100vh;
  }
  
  .breakpoint-post-form {
    display: flex;
    flex-shrink: 0;
    overflow: hidden;
    box-sizing: border-box;
    margin-top: -1em;
  }

  .desktop-post-width {
    width: 420px;
    overflow-y: auto;
    flex-grow: 1;
  }

  .width-custom {
    width: 400px;
    margin-left: 1em;
    padding-left: 1em;
  }
 
}


/**** Mobile ****/
@media screen and ( max-width: 47em ) {
  .breakpoint {
    margin: 0;
    padding: 0;
   
  }
  .width-100p {
    width: 100%;
  }

  .margin-sides {
      margin: 0;
      padding: 0;
  }

  .width-custom {
    min-width: 260px;
    max-width: 380px;
    margin-left: 1em;
    margin-right: 1em;
    padding-left: 1em;
    padding-right: 1em;
  }

  body {
    overflow-y: scroll;
  }

}

/**** Desktop ****/
@media screen and ( min-width: 60em ) {
  .desktop-post-width {
    max-width: 55vw;
    flex-grow: 1;
    height: 100vw;
  }

  .breakpoint-post-form {
    justify-content: center;
  }

} 

/**** Mid-sized devices ****/
@media screen and ( min-width: 28em ) and ( max-width: 47em ) {
  .breakpoint-post-form {
    justify-content: center;
    padding-left: 1em;
    padding-right: 1em;
  }


  .width-custom {
    min-width: 260px;
    max-width: 380px;
   
  }

} 

.background-comments {
  background-color: rgba(184, 184, 184, 0.4);
}

.background-light-grey {
  background-color: #f8f8f8;
  border-radius: 8px;
}

.background-medium-grey {
  border-radius: 8px;
}

.margin {
  margin-top: -4em;
  margin-bottom:1em;
  padding-bottom: 2em;
  padding-top: 4em;
}

.padding-sides {
  padding-left: 1.2em;
  padding-right: 1.2em;
}

.gradient {
  background-image: -webkit-linear-gradient(left, rgb(75, 89, 218), rgb(67, 182, 211)); /* For Chrome and Safari */
  background-image:    -moz-linear-gradient(left, rgb(75, 89, 218), rgb(67, 182, 211)); /* For old Fx (3.6 to 15) */
  background-image:     -ms-linear-gradient(left, rgb(75, 89, 218), rgb(67, 182, 211)); /* For pre-releases of IE 10*/
  background-image:      -o-linear-gradient(left, rgb(75, 89, 218), rgb(67, 182, 211)); /* For old Opera (11.1 to 12.0) */
  background-image:         linear-gradient(to right,  rgb(75, 89, 218), rgb(67, 182, 211)); 
  background-image:         linear-gradient(to bottom,  #fff, #fff); 
  color:transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.white {
  color: #fff;
}


.purple {
  color: #2F2D56;
}
#primary-search-account-menu-mobile > div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
  background-color: #161436;
}

.width-20vw {
  width: 20vw;
}


#root {
  height: 100vw;
  background-color: #161436;
}

body {
  background-color: #161436;
}